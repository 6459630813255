<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group label="PN" label-for="searchConsultant">
              <all-select
                id="searchConsultant"
                v-model="consultant"
                :options="consultants"
                label="name"
                take-this="id"
                track-by="id"
                multiple
                :close-on-select="false"
                :loading="loading.consultants"
              ></all-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group label="Franqueado" label-for="searchFranchise">
              <all-select
                id="searchFranchise"
                v-model="franchise"
                :options="franchises"
                label="name"
                take-this="id"
                track-by="id"
                multiple
                :close-on-select="false"
                :loading="loading.franchises"
              ></all-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2 text-right">
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              class="mr-1"
              :disabled="loading.search"
            >
              Limpar
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="search()"
              :disabled="loading.search"
            >
              <b-spinner v-if="loading.search" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading.search ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <b-row class="d-flex align-items-center justify-content-end">
              <div class="mx-1" cols="12" md="6">
                <b-button
                  variant="outline-primary"
                  :disabled="loading.export"
                  @click.prevent="exportData()"
                >
                  <b-spinner v-if="loading.export" small />
                  <feather-icon
                    v-else
                    icon="ArrowDownCircleIcon"
                    class="mr-50"
                  />
                  <span>
                    {{ loading.export ? "Aguarde..." : "Exportar planilha" }}
                  </span>
                </b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados contratos para esta busca"
        :busy="loading.search"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: Data de inativação  -->
        <template #cell(formated_payment_date)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.formatted_date
            }}</span>
          </div>
        </template>
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.consultant_name">
              <b-button
                :id="`consultant-pop-icon-${data.item.consultant_id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  :id="`consultant-pop-icon-${data.item.consultant_id}`"
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer center"
                />
              </b-button>
              <user-detail-popover
                :target="`consultant-pop-icon-${data.item.consultant_id}`"
                type="consultant"
                :id="data.item.consultant_id"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.consultant_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.customer_name">
              <b-button
                :id="`customer-pop-icon-${data.item.customer_id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <user-detail-popover
                :target="`customer-pop-icon-${data.item.customer_id}`"
                type="customer"
                :id="data.item.customer_id"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.customer_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Id do contrato -->
        <template #cell(franchise_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              {{ data.item.franchise_name }}
            </span>
          </div>
        </template>
        <!-- Column: Id do contrato -->
        <template #cell(contract_id)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-center">
              {{ data.item.contract_id }}
            </span>
          </div>
        </template>
        <!-- Column: Status do contrato -->
        <template #cell(contract_status)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              {{ data.item.contract_status }}
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              :value="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormGroup,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import AllSelect from "@/modules/shared/components/AllSelect";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    vSelect,
    UserDetailPopover,
    AllSelect,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: {
        consultants: false,
        franchises: false,
        search: false,
        export: false,
      },
      tableColumns: [
        { key: "formatted_date", label: "Data de inativação", sortable: false },
        { key: "consultant_name", label: "PN", sortable: false },
        { key: "franchise_name", label: "Franquia", sortable: false },
        { key: "customer_name", label: "Cliente", sortable: false },
        { key: "contract_id", label: "Id do Contrato", sortable: false },
        {
          key: "contract_status",
          label: "Status do Contrato",
          sortable: false,
        },
      ],
      order: undefined,
      sortField: null,
      rangeDate: undefined,
      currentPage: 1,
      itemsPerPage: 1,
      franchise: [],
      consultant: [],
    };
  },
  computed: {
    ...mapGetters({
      franchises: sharedTypes.FRANCHISES,
      consultants: sharedTypes.CONSULTANTS,
      contracts: types.INACTIVE_PN_CONTRACTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.contracts?.data || [];
    },
    totalItems: function () {
      return this.contracts?.total || 0;
    },
    pageItemsFrom: function () {
      return this.contracts?.from || 0;
    },
    pageItemsTo: function () {
      return this.contracts?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os franqueados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
  },
  methods: {
    ...mapActions({
      getFranchises: sharedTypes.GET_FRANCHISES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      searchContracts: types.SEARCH_INACTIVE_PN_CONTRACTS,
      exportContracts: types.EXPORT_INACTIVE_PN_CONTRACTS,
    }),
    goToContractDetails(row, index) {
      this.$router.push({
        name: "contract-details-summary",
        params: { id: row.contract_id },
      });
    },
    search(currentPage) {
      this.loading.search = true;
      this.currentPage = currentPage;
      this.searchContracts({
        franchise: this.franchise,
        consultant: this.consultant,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : 1,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.search = false;
        });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    clearSearch() {
      this.rangeDate = undefined;
      this.search(1);
    },
    exportData() {
      this.loading.export = true;
      this.exportContracts({
        order: this.order,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.export = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
